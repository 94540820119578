import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/common/components/analytics/google/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/common/components/analytics/klaviyo/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/common/components/analytics/matomo/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/common/components/cookie-consent/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/common/components/intercom/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/layout/components/offer-banner/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/modules/providers/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"variable\":\"--font-poppins\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"variable\":\"--font-roboto\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}")