import React, { createContext, useContext, useState, useEffect } from 'react';

interface CookieConsentContext {
    consentGiven: boolean,
    setConsent: (consentGiven: boolean) => void
}

const defaultContextValue: CookieConsentContext= {
    consentGiven: false,
    setConsent: () => {}, // Empty function as a placeholder
};

const CookieConsentContext = createContext<CookieConsentContext>(defaultContextValue)

export const useCookieConsent = () => useContext(CookieConsentContext);

interface CookieConsentProviderProps {
    children?: React.ReactNode
}

export const CookieConsentProvider = ({ children }: CookieConsentProviderProps) => {
    const [consentGiven, setConsent] = useState<boolean>(false);

    useEffect(() => {
        const storedConsent = localStorage.getItem('cookieConsent')
        setConsent(storedConsent === 'true')
    }, []);

    return (
        <CookieConsentContext.Provider value={{ consentGiven, setConsent }}>
            {children}
        </CookieConsentContext.Provider>
    );
};