'use client'

import { useSubscription } from "@lib/context/subscription-context"
import Subscriptions from "app/(main)/account/(dashboard)/subscriptions/page"

const OfferBanner = () => {
    const { subscription } = useSubscription()

    const handleOfferClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        window.klaviyo = window.klaviyo || []; window.klaviyo.push(['openForm', 'UkfFYe'])
        return false
    }

    return (
        <>
            {!subscription &&
                <div className="bg-weekly-green text-center text-white">
                    <a href="" onClick={handleOfferClick}>Click here to claim £20 off your first order when you spend £40 or more</a>
                </div>
            }
        </>
    )
}

export default OfferBanner