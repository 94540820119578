'use client'
import React, { useEffect } from "react"

export const MatomoAnalytics = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            loadMatomo()
        }
    }, [])

    const loadMatomo = () => {
        const d = document
        var _mtm = window._mtm = window._mtm || []
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'})
        const scriptSrc = 'https://matomo.weekly.shop/js/container_4KejSsZ2.js'

        // Check if the script already exists
        if (!Array.from(d.getElementsByTagName('script')).some(script => script.src === scriptSrc)) {
            const g = d.createElement('script')
            const s = d.getElementsByTagName('script')[0]
            g.async = true
            g.src = scriptSrc
            s.parentNode?.insertBefore(g, s)
        }
    }

    return null
}
