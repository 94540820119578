'use client'

import { useKlaviyo } from '@lib/context/klaviyo';
import React, { useEffect } from 'react';

export const Klaviyo = () => {
    const { setKlaviyo } = useKlaviyo();

    useEffect(() => {
        const script: HTMLScriptElement = document.createElement('script');
        script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WArRt5";
        script.async = true;

        script.onload = () => {
            const maxWaitTime = 10_000
            const pollingInterval = 100
            let timeElapsed = 0;

            const checkKlaviyo = setInterval(() => {
                if (window.klaviyo) {
                    setKlaviyo(window.klaviyo);
                    clearInterval(checkKlaviyo);
                } else if (timeElapsed >= maxWaitTime) {
                    clearInterval(checkKlaviyo);
                    console.error('Klaviyo script loaded, but window.klaviyo is still undefined after 5 seconds.');
                } else {
                    timeElapsed += pollingInterval;
                }
            }, pollingInterval);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [setKlaviyo]);

    return null;
};