import { ProductCategory } from '@medusajs/medusa'
import { createContext, useContext, useState, ReactNode } from 'react'

interface BreadcrumbContextType {
    breadcrumbCategory: ProductCategory | undefined
    setBreadcrumbCategory: (category: ProductCategory) => void
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined)

export const BreadcrumbProvider = ({ children }: { children: ReactNode }) => {
    const [breadcrumbCategory, setBreadcrumbCategory] = useState<ProductCategory>()

    return (
        <BreadcrumbContext.Provider value={{ breadcrumbCategory, setBreadcrumbCategory }}>
            {children}
        </BreadcrumbContext.Provider>
    )
}

export const useBreadcrumb = () => {
    const context = useContext(BreadcrumbContext)
    if (context === undefined) {
        throw new Error('useBreadcrumb must be used within a BreadcrumbProvider')
    }
    return context
}