'use client'
import { useCookieConsent } from '@lib/context/cookie-consent';
import { useEffect } from 'react';
import { IDatalayerEntry } from 'types/global';

const GoogleAnalytics = () => {
    useEffect(() => {
            loadGoogleTagManager();
    }, [])

    const loadGoogleTagManager = () => {
        if (typeof window.gtag !== 'undefined') return;

        // Load the Google Analytics script
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-GN7J57C10C`;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer!.push(arguments as unknown as IDatalayerEntry);
            };
            window.gtag('js', new Date());
            window.gtag('config', 'G-GN7J57C10C');
        };
    };

    return null
};

export default GoogleAnalytics;