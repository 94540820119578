'use client'
import { useCookieConsent } from '@lib/context/cookie-consent';
import { useEffect, useState } from 'react'

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false)
    const { setConsent } = useCookieConsent();

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent')
        if (!cookieConsent) {
            setIsVisible(true)
        }
    }, [])

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true')
        setIsVisible(false)
        setConsent(true)
        // third-party cookies here
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'false')
        setIsVisible(false)
        setConsent(false)
    };

    if (!isVisible) {
        return null
    }

    return (
        <div className="fixed w-full bottom-0 text-center bg-white border-t border-gray-200 flex flex-row justify-center align-middle items-center p-2">
            <p className="px-3 ">This website uses packaging-free cookies to enhance your experience.</p>
            <button onClick={handleAccept} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2">OK</button>
            <button onClick={handleDecline} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700">Decline</button>
        </div>
    )
}

export default CookieConsent