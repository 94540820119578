'use client'

import { MEDUSA_BACKEND_URL, queryClient } from '@lib/config'
import { AccountProvider } from '@lib/context/account-context'
import { CartDropdownProvider } from '@lib/context/cart-dropdown-context'
import { MobileMenuProvider } from '@lib/context/mobile-menu-context'
import { StoreProvider } from '@lib/context/store-context'
import { CartProvider, MedusaProvider } from 'medusa-react'
import React from 'react'
import { CheckoutProvider } from '@lib/context/checkout-context'
import { SubscriptionProvider } from '@lib/context/subscription-context'
import { BreadcrumbProvider } from '@lib/context/breadcrumb-context'
import { CookieConsentProvider } from '@lib/context/cookie-consent'
import { KlaviyoProvider } from '@lib/context/klaviyo'

export default function Providers({ children, hasPostcode }: { children: React.ReactNode, hasPostcode: boolean }) {
    return (
        <MedusaProvider
            baseUrl={MEDUSA_BACKEND_URL}
            queryClientProviderProps={{
                client: queryClient,
            }}
        >
            <CookieConsentProvider>
                <KlaviyoProvider>
                    <CartDropdownProvider>
                        <MobileMenuProvider>
                            <CartProvider>
                                <SubscriptionProvider>
                                    <AccountProvider>
                                        <StoreProvider hasPostcode={hasPostcode}>
                                            <BreadcrumbProvider>
                                                <CheckoutProvider>{children}</CheckoutProvider>
                                            </BreadcrumbProvider>
                                        </StoreProvider>
                                    </AccountProvider>
                                </SubscriptionProvider>
                            </CartProvider>
                        </MobileMenuProvider>
                    </CartDropdownProvider>
                </KlaviyoProvider>
            </CookieConsentProvider>
        </MedusaProvider >
    )
}
