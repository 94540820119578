'use client'

import { useAccount } from "@lib/context/account-context"
import { useEffect } from "react"

const Intercom = () => {
    const { customer } = useAccount()

    useEffect(() => {
        const APP_ID = 'gmlvy0tg'

        window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: APP_ID,
            user_id: customer?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: customer?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: customer?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email address
            created_at: customer?.created_at, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date
        }

        const w = window as any
        const ic = w.Intercom
        if (typeof ic === "function") {
            ic('update', w.intercomSettings)
        } else {
            const d = document
            const i = function () { i.c(arguments) }
            i.q = [] as any
            i.c = function (args: any) { i.q.push(args) }
            w.Intercom = i
            const l = function () {
                const s = d.createElement('script')
                s.type = 'text/javascript'
                s.async = true
                s.src = 'https://widget.intercom.io/widget/' + APP_ID
                const x = d.getElementsByTagName('script')[0]
                x.parentNode?.insertBefore(s, x)
            }
            if (document.readyState === 'complete') {
                l()
            } else if (w.attachEvent) {
                w.attachEvent('onload', l)
            } else {
                w.addEventListener('load', l, false)
            }
        }
    }, [customer])

    return null
}

export default Intercom